<template>
	<div class="create-inventory">
		<div class="col-xl-12">
      <div class="row">
        <div class="col-xl-12"><hr><br><h3>Inventory Quantities:</h3><br></div>
        <div class="col-xl-6">
            <div class="form-group">
                <label>In Stock</label>
                <input type="text" class="form-control form-control-solid form-control-lg" v-model="form.stock_quantity" />
                <span class="form-err" v-if="form.errors.has('stock_quantity')" >
                    {{form.errors.get('stock_quantity')}}
                </span>
            </div>
        </div>
        
        <div class="col-xl-6">
            <div class="form-group">
                <label>Reorder</label>
                <input type="text" class="form-control form-control-solid form-control-lg" v-model="form.reorder" />
                <span class="form-err" v-if="form.errors.has('reorder')" >
                    {{form.errors.get('reorder')}}
                </span>
            </div>
        </div>

        <div class="col-xl-6">
            <div class="form-group">
                <label>Minimum Quantity</label>
                <input type="text" class="form-control form-control-solid form-control-lg" v-model="form.min_quantity" />
                <span class="form-err" v-if="form.errors.has('min_quantity')" >
                    {{form.errors.get('min_quantity')}}
                </span>
            </div>
        </div>

        <div class="col-xl-6">
            <div class="form-group">
                <label>Stock Status</label>
                <select class="select2 form-control form-control-solid form-control-lg" v-model="form.stock_status">
                    <option value="null" >Select Status</option>
                    <option value="instock">In Stock</option>
                    <option value="outofstock">Out of Stock</option>
                    <option value="backorder">Back Order</option>
                </select>
            </div>
        </div>
      </div>
			<div class="row" >
				<div class="col-xl-12">
				<button type="button" class="btn btn-sm btn-primary custom-button-primary custom-addrem-btn m-b-20" @click="updateInventory()">Update</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Form from '@/core/services/api.form.services'
import { globalEvents } from '@/utils/globalEvents'
export default {
  name: 'editinventoryquantity',
    props: {
     inventory: {
      type: Object,
      default() {
        return {}
      }
     }
    },
    data() {
    return {
      loading:false,
      form : new Form({
       stock_quantity: null,
       reorder : null,
       min_quantity : null,
       stock_status : null,
       active: null
	  }),
      product_id : null,
      img_preview: '/ctshub/media/bg/profile-img.png',
      image_btn_text: null,
      submit_btn_text: 'Update',
      title_text: 'Inventory Quantities',
      response_msg: 'Changes updated successfully.',
    }
  },
  components: {},
  created() {
      if(this.inventory.id)
      {
          this.form = new Form(this.inventory)
      }
  },
  methods: {

    updateInventory: function() {
      this.loading=true;
      this.form.post('product/edit/' + this.form.id).then((response) => {
        if(response.status){
          this.loading=false;
          globalEvents.$emit('InventoryUpdated', response.data)
          this.$emit('close')
       }
      })
    },
  }

}
</script>
